import { Component, Prop, Vue } from "vue-property-decorator";
import { Firm, FirmData } from "@planetadeleste/vue-mc-gw";
import { set } from "lodash";

@Component
export default class FirmMixin extends Vue {
  @Prop({ type: Object, required: true }) readonly firm!: FirmData | Firm;
  @Prop(Boolean) readonly required!: boolean;

  get isFirmModel() {
    return this.firm instanceof Firm;
  }

  setFirmValue(sKey: string, sValue: any) {
    if (this.isFirmModel) {
      // @ts-ignore
      this.firm.set(sKey, sValue);
    } else {
      set(this.firm, sKey, sValue);
    }
  }
}
